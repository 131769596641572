<template>
    <div>
       
        <v-card>
            <v-card-title>Update Student Year</v-card-title>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="3">

                    <v-autocomplete :items="institute_list" item-text="name" item-value="id" v-model="institute"
                        @change="getProgramType()" outlined> <template #label>
                            Select Organization<span style="color:red;">*</span>
                        </template></v-autocomplete>
                </v-col>
                <v-col md="3">

                    <v-autocomplete autocomplete="off" :items="programtype_list" item-text="name" item-value="id"
                        v-model="programtype" @change="getProgramAndYear()" outlined>
                        <template #label>
                            Select Program Type<span style="color:red;">*</span>
                        </template></v-autocomplete>
                </v-col>
                <v-col md="3">

                    <v-autocomplete autocomplete="off" :items="program_list" item-text="name" item-value="id" outlined
                        v-model="program">
                        <template #label>
                            Select Program <span style="color:red;">*</span>
                        </template></v-autocomplete>
                </v-col>
                <v-col md="3">

                    <v-autocomplete autocomplete="off" :items="year_list" item-text="name" item-value="id" outlined
                        v-model="year">
                        <template #label>
                            Select year <span style="color:red;">*</span>
                        </template></v-autocomplete>
                </v-col>


            </v-row>
            <v-row justify="center">
                <v-col md="2">
                    <v-btn class="primary ma-5 mt-6" @click="getstudentstoupdateyaer">Get Details</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <!-- <v-row justify="end" class="mr-5">
                <v-btn v-if="learnerlist.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row> -->

            <v-row v-if="learnerlist.length != 0">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Sr. No</th>
                            <th class="text-left">
                                <v-row>
                                    Selected
                                    <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px"
                                        type="checkbox" @click="checkAll(learnerlist)" />
                                </v-row>
                            </th>
                            <th class="text-left">Name</th>
                            <th class="text-left">GRNO</th>
                            <th class="text-left">Program</th>
                            <th class="text-left">Year</th>
                            <th class="text-left">Organization</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in learnerlist" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <input style="height: 40px; width: 30px" type="checkbox" v-model="item.isselected" />
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.grno }}</td>
                            <td>{{ item.program }}</td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.org }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-row>

            <v-row>

                <div v-if="learnerlist.length != 0">
                    <v-row>
                        <v-col sm=1></v-col>
                        <v-col sm=6>
                            <label><b>Year &nbsp;<small style="color:red;">*</small></b></label>
                            <v-autocomplete autocomplete="off" :items="year_list" item-text="name" item-value="id"
                                v-model="updatedyear"></v-autocomplete>
                        </v-col>
                        <v-col sm=4>
                            <v-btn class="btn-title ma-5 mt-8 center" dark color="indigo" @click="updateyearofstudents()">
                                Update Year</v-btn>
                        </v-col>
                    </v-row>
                </div>

            </v-row>
        </div>

        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import Language from "../AdminMaster/Language.vue";
import { table2excel } from "../jquery.table2excel";
export default {
    components: { Language },
    data() {
        return {
            snackbar_msg: "",
            snackbar: false,
            color: "",
            search: "",
            overlay: false,


            ay_list: [],
            institute_list: [],
            program_list: [],
            year_list: [],
            placementtype_list: [],
            programtype_list: [],

            ay: null,
            institute: null,
            program: null,
            programtype: null,
            updatedyear: null,
            year: null,
            placementtype: [],
            selectall: false,
            isselected: false,
            ayid: null,
            progid: null,
            programtypeid: null,
            ptid: null,
            yearid: null,
            orgid: null,
            learnerlist: [],
            regdashboard_list: [],
            programlist: [],
            programtotal_list: [],

        };
    },
    mounted() {

        this.init();
    },

    methods: {
        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "PLACEMENT_DASHBOARD_REPORT", //do not include extension
                fileext: ".xls" // file extension
            });
        },

        init() {

            axios.post("/TPO/getupdateyearfilters").then((res) => {
                if (res.data.msg == "200") {

                    this.institute_list = res.data.institute_list;

                    // this.getProgramType();
                } else {
                    console.log("error fetching data!");
                }
            });
        },


        getstudentstoupdateyaer() {
            if (this.institute == '' || this.institute == null) {
                this.showSnackbar("red", "Please Select Organization..!");
                return;
            }
            if (this.programtype == '' || this.programtype == null) {
                this.showSnackbar("red", "Please Select Program Type..!");
                return;
            }
            if (this.program == '' || this.program == null) {
                this.showSnackbar("red", "Please Select Program..!");
                return;
            }
            if (this.year == '' || this.year == null) {
                this.showSnackbar("red", "Please Select Year..!");
                return;
            }
            const params = {
                instituteid: this.institute,
                programtypeid: this.programtype,
                programid: this.program,
                yearid: this.year,
            };
            this.overlay = true;

            axios
                .post("/TPO/getstudentstoupdateyaer", params)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {

                        this.learnerlist = res.data.learnerlist;

                    } else if (res.data.msg == "402") {
                        this.showSnackbar("red", res.data.error);
                    }
                });
        },

        updateyearofstudents() {

            this.overlay = true;
            const params = {
                list: this.learnerlist,
                yearid: this.updatedyear,
                instituteid: this.institute,
                programid: this.program,
            };
            axios
                .post("/TPO/updateyearofstudents", params)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.overlay = false;
                        //     this.learnerlist = res.data.learnerlist;
                        this.init();
                        this.showSnackbar("green", "Year Updated");
                    } else if (res.data.code == "NO") {
                        this.overlay = false;
                        this.showSnackbar("red", res.data.msg);
                    } else if (res.data.msg == "402") {
                        this.showSnackbar("red", res.data.error);
                    }
                });
        },

        checkAll(hello) {

            for (var i = 0; i < this.learnerlist.length; i++) {
                if (!this.selectall) {
                    this.learnerlist[i].isselected = true;
                } else {
                    this.learnerlist[i].isselected = false;
                }
            }
        },

        getProgramType() {
            const data = {
                instituteid: this.institute,
            };
            axios
                .post("PlacementReport/getProgramTypeByOrganization", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.programtype_list = res.data.programtype_list;
                        this.programtype = this.programtype_list[0].id;

                        this.getProgramAndYear();
                    } else {
                        this.showSnackbar("green", res.data.msg1);
                    }
                }).catch((error) => {
                    window.console.log(error);
                }).finally(() => { });
        },

        getProgramAndYear() {
            const data = {
                instituteid: this.institute,
                programtypeid: this.programtype,
            };
            axios
                .post("TPO/getProgramAndYearinUpdateyear", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.program_list = res.data.program_list;
                        this.year_list = res.data.year_list;

                    } else {
                        this.showSnackbar("green", res.data.msg1);
                    }
                }).catch((error) => {
                    window.console.log(error);
                }).finally(() => { });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        route(companyofferingid, progid, programtypeid, orgid, yearid, placementtypeid, ayid) {
            let routeData = this.$router.resolve({ name: 'LearnerCollegePlacementDashboard', query: { companyofferingid: companyofferingid, progid: progid, programtypeid: programtypeid, orgid: orgid, yearid: yearid, placementtypeid: placementtypeid, ayid: ayid } });
            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style scoped>
.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.tc-title {
    background-image: linear-gradient(-90deg, skyblue, #057996);
    color: #fff;
    border-radius: 3px;
}
</style>    